<style scoped lang="less">
.frame-panel {
  flex: 1;
  justify-content: flex-start;
  height: 100%;

  .img-wrap {
    position: relative;
    .desc {
      position: absolute;
      left: 0;
      top: 0;
      padding: 30px;
    }
  }
}
.frame-panel-drawer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  .imgItemBox {
    width: 50%;
    height: 50%;
    .addIcon {
      display: none;
      width: 100px;
      height: 100px;
      position: relative;
      top: calc(-50% - 30px);
      left: calc(50% - 50px);
    }
  }
}
.el-image {
  // position: relative;
  // width: auto;
  // height: auto;
  // max-height: 50%;
  // max-width: 50%;
  // left: 50%;
  // transform: translateX(-50%);
  // padding: 5px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid black;
}
.frameShow .el-tabs {
  height: 100%;
}
.main-content {
  height: calc(100vh - 4rem);
}

.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.contextmenu li {
  margin: 0;
  padding: 0 16px;
  cursor: pointer;
}

.contextmenu li:hover {
  background: #eee;
}
.target-title {
  box-sizing: border-box;
  padding: 10px;
  .title-item {
    margin-bottom: 5px;
  }
}
.targets-wrapper {
  box-sizing: border-box;
  padding: 10px;
  // background-color: aqua;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  // border: 1px solid #000;

  .img {
    position: relative;
    box-sizing: border-box;
    display: flex;
    // height: 160px;
    min-width: 160px;
    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 9;
    }
  }

  .desc {
    padding: 5px;
    box-sizing: border-box;
    border-left: 4px solid #409eff;
    .desc-item {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}
.highlignt {
  border: 2px solid #409eff !important;
}
.frameShow {
  box-sizing: border-box;

  .img {
    position: relative;
    height: 100%;
    .el-image {
      // max-width: 95%;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    .desc {
      z-index: 9;
      position: absolute;
      height: 30px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      color: #fff;
      left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>

<template>
  <div class="main-content">
    <div class="crt-content">
      <el-switch
        style="display: block; margin: 20px"
        v-model="imgFlag"
        active-color="#2980b9"
        inactive-color="#2980b9"
        active-text="显示报告图像"
        inactive-text="显示当前检查下的测量记录"
      ></el-switch>
      &emsp;
      <el-input-number size="mini" v-model="rows" :min="1" :max="10"></el-input-number>
      &emsp;X &emsp;
      <el-input-number size="mini" v-model="columns" :min="1" :max="10"></el-input-number>
      <!-- <el-button
        type="primary"
        size="mini"
        icon="el-icon-postcard"
        v-debounce="handleBtn"
        style="position: absolute; right: 10px"
      >
        查看图片列表
      </el-button> -->
    </div>
    <div style="height: calc(99% - 3rem); display: flex">
      <div
        style="
          max-width: 350px;
          min-width: 300px;
          border: 1px solid black;
          padding: 20px 0;
          box-sizing: border-box;
        "
      >
        <!-- <el-tree
          class="filter-tree"
          :data="treeData"
          :props="defaultProps"
          default-expand-all
          @node-click="nodeClick"
        ></el-tree> -->
        <!-- 报告 -->

        <el-tabs v-model="currentExamineNameIndex" tab-position="left" v-if="showList">
          <div style="text-align: center; margin-bottom: 10px"><span>报告影像</span></div>
          <el-tab-pane
            :label="item.name"
            :name="'' + index"
            v-for="(item, index) in examineNameList"
            :key="index"
          >
            <div
              class="targets-wrapper"
              v-for="(ele, i) in showList[item.value]"
              :key="i"
              @click="reportImgClick(ele)"
            >
              <div class="img">
                <el-image
                  style="width: 160px; height: 160px"
                  :src="baseUrl + ele.imageUrl"
                  alt=""
                ></el-image>
                <div class="icon" @click.stop="deleteImgFromReport(ele)">
                  <img src="../../../assets/img/delete.png" alt="" />
                </div>
              </div>
              <div class="desc">病变部位: {{ ele.lesionName }}</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        class="frameShow"
        style="flex: 1; margin: 0 20px; border: 1px solid #e4e7ed; box-sizing: border-box"
      >
        <el-tabs v-model="currentLesionNameIndex" type="card" v-if="!imgFlag">
          <el-tab-pane
            v-for="(ops, idx) in lesionNames"
            :key="ops + idx"
            :label="examineNameList[currentExamineNameIndex].value + '-' + ops"
            :name="'' + idx"
            style="height: 100%"
          >
            <div class="frame-panel">
              <el-image
                v-for="(item, index) in showMeasurementsList"
                :key="index"
                :src="item.imageUrl"
                @contextmenu.prevent="openMenu($event, index)"
                fit="contain"
                @click=""
                :style="`width:calc(100% / ${columns});height:calc(100% / ${rows});object-fit: contain;${
                  item.checked ? 'border: 4px solid #409eff !important' : ''
                }`"
              ></el-image>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div v-if="currentShowItem && imgFlag" class="img">
          <div class="desc">
            <div v-if="currentShowItem">
              <span v-show="currentShowItem.examineName">
                检查名称: {{ currentShowItem.examineName }}&emsp;
              </span>
              <span v-show="currentShowItem.lesionName">
                病变部位: {{ currentShowItem.lesionName }}&emsp;
              </span>
              <span v-show="currentShowItem.itemTitle">
                {{ currentShowItem.itemTitle }}: {{ currentShowItem.itemValue }}
                {{ currentShowItem.measureUnit }}&emsp;
              </span>
            </div>
          </div>
          <el-image
            :src="currentShowItem.imageUrl"
            fit="contain"
            :style="`width:100%;height:100%;object-fit: contain;`"
          ></el-image>
        </div>
      </div>
      <div
        style="
          min-width: 300px;
          border: 1px solid #000;
          padding: 20px 0;
          box-sizing: border-box;
          max-height: 96vh;
          overflow-y: auto;
        "
      >
        <!-- 测量记录 -->
        <el-tabs tab-position="right">
          <div class="target-title">
            <div class="title-item">
              检查名称:
              <el-tag size="mini">{{ examineNameList[currentExamineNameIndex].value }}</el-tag>
            </div>
            <div class="title-item">
              病变部位:
              <el-tag size="mini">{{ lesionNames[currentLesionNameIndex] }}</el-tag>
            </div>
          </div>
          <el-tab-pane
            :label="item.groupName"
            v-for="(item, index) in measurementsList"
            :key="index"
          >
            <div
              class="targets-wrapper"
              v-for="(ele, i) in item.items"
              :key="i"
              @click="targetClick(ele)"
              v-if="
                (ele.lesionName === lesionNames[currentLesionNameIndex] &&
                  ele.examineName === examineNameList[currentExamineNameIndex].value) ||
                (lesionNames[currentLesionNameIndex] == '全部' &&
                  ele.examineName === examineNameList[currentExamineNameIndex].value)
              "
              :style="`${
                ele.checked
                  ? 'background: linear-gradient(to right, rgba(64, 158, 255, 0.3), #fff)'
                  : ''
              }`"
            >
              <div class="desc">
                <!-- {{ ele.lesionName }} {{  lesionNames[currentLesionNameIndex] }}
                {{ ele.examineName}} {{  examineNameList[currentExamineNameIndex].value }} -->
                <!-- <div class="desc-item">检查名称: {{ ele.examineName }}</div>-->
                <div class="desc-item" v-if="lesionNames[currentLesionNameIndex] == '全部'">
                  病变部位: {{ ele.lesionName }}
                </div>
                <div class="desc-item">
                  {{ ele.itemTitle }}: {{ ele.itemValue }} {{ ele.measureUnit }}
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
        <li><el-button type="text" @click="handleDel" :disabled="isAdd">从报告删除</el-button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ImageProcess',
  data() {
    return {
      imgFlag: false,
      baseUrl: window.location.origin + '/api/v1/webconsole/downloadcenter/download',
      menu: '',
      activeName: 'first',
      drawerActiveName: 'first',
      selectLesion: '左主干',
      drawerSelectLesion: '左主干',
      treeData: [],
      tabData: [],
      drawerTabData: [],
      rows: 2,
      columns: 4,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      frames: {},
      drawerFrames: {},
      visible: false,
      isAdd: true,
      top: 0,
      left: 0,
      addIndex: '',
      imgInfoDrawer: false,
      // 加入到报告图像
      reportImages: [],
      showList: {},
      examineNameList: [
        {
          checked: false,
          name: 'DSA',
          value: 'DSA'
        },
        // {
        //   checked: false,
        //   name: 'PCI',
        //   value: 'PCI'
        // },
        {
          checked: false,
          name: 'CTA',
          value: 'CTA'
        },
        {
          checked: false,
          name: 'MRA',
          value: 'MRA'
        },
        // {
        //   checked: false,
        //   name: 'XA',
        //   value: 'XA'
        // },
        // {
        //   checked: false,
        //   name: '超声',
        //   value: 'US'
        // },
        {
          checked: false,
          name: 'FF',
          value: 'FF'
        },
        {
          checked: false,
          name: 'IMR',
          value: 'IMR'
        },
        {
          checked: false,
          name: 'CTP',
          value: 'CTP'
        },
        {
          checked: false,
          name: 'MRP',
          value: 'MRP'
        },
        {
          checked: false,
          name: 'IVUS',
          value: 'IVUS'
        },
        {
          checked: false,
          name: 'OCT',
          value: 'OCT'
        }
      ],
      currentExamineNameIndex: '0',
      currentShowItem: null,
      // 测量记录
      measurementsList: [],
      // 按照检查名、病变部位分类的测量记录
      flatMeasurementsList: [],
      // 获取到的所有存在的病变部位
      lesionNames: ['全部'],
      currentLesionNameIndex: '0'
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    }),
    showMeasurementsList() {
      let currentLesionName = this.lesionNames[this.currentLesionNameIndex]
      let currentExamineName = this.examineNameList[this.currentExamineNameIndex].value
      if (currentLesionName == '全部')
        return this.flatMeasurementsList.filter((item) => item.examineName === currentExamineName)
      return this.flatMeasurementsList.filter(
        (item) => item.examineName === currentExamineName && item.lesionName === currentLesionName
      )
    },
    lesionTabWithExamineName() {
      let currentExamineName = this.examineNameList[this.currentExamineNameIndex].value
      return this.lesionNames.map((item) => (item = `${currentExamineName}-${item}`))
    }
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    // this.getMenu()
    this.queryReportImages()
    this.queryMeasurements()
  },
  methods: {
    // 获取该手术的测量记录
    queryMeasurements() {
      this.$api
        .post(`/v1/webconsole/pacs/operation/measure/group_items/${this.$route.query.operationId}`)
        .then(({ data }) => {
          // console.log('手术下的指标', data);
          this.measurementsList = data.data
          this.measurementsList.forEach((item) => {
            item.items.forEach((ele, i, arr) => {
              arr[i].checked = false
            })
          })
          let flat = []
          data.data.forEach((item) => {
            item.items.forEach((ele) => {
              this.lesionNames.push(ele.lesionName)
              let obj = { ...ele, groupName: item.groupName, checked: false }
              obj.imageUrl = this.baseUrl + ele.imageUrl
              flat.push(obj)
            })
          })
          this.flatMeasurementsList = flat
          this.lesionNames = [...new Set(this.lesionNames)]
        })
        .catch((err) => {
          alert('imageProcess-index-query operation targets measurements-error', err)
        })
    },
    // 获取该手术的报告图像
    queryReportImages() {
      this.$api
        .post(`/v1/webconsole/pacs/report/operation/${this.$route.query.operationId}`)
        .then(({ data }) => {
          this.reportImages = data.data
          this.showList = null
          this.showList = {}
          data.data.forEach((item) => {
            if (this.showList.hasOwnProperty(item.examineName)) {
              this.showList[item.examineName].push(item)
            } else {
              this.showList[item.examineName] = [item]
            }
          })
          // console.log(this.showList);
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 点击指标
    targetClick(val) {
      // ;
      this.measurementsList.forEach((item) => {
        item.items.forEach((ele, i, arr) => {
          if (val.id == ele.id) {
            arr[i].checked = true
          } else {
            arr[i].checked = false
          }
        })
      })
      this.flatMeasurementsList.forEach((item, i, arr) => {
        if (item.id == val.id) {
          arr[i].checked = true
        } else {
          arr[i].checked = false
        }
      })
    },
    // 点击报告图像,展示报告影像
    reportImgClick(val) {
      this.currentShowItem = { ...val }
      this.currentShowItem.imageUrl = this.baseUrl + val.imageUrl
    },
    // 初始化选中效果
    initChecked() {
      this.showMeasurementsList.forEach((item, i, arr) => {
        arr[i].checked = false
      })
    },
    // 删除报告中的图像
    deleteImgFromReport(row) {
      this.$api
        .post(`/v1/webconsole/pacs/report/delete/${row.id}`)
        .then(({ data }) => {
          // console.log(data);
          if (data.status == 200) {
            this.queryReportImages()
            this.$message.success(data.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cardEnter(val) {
      let id = 'img' + val.id
      document.getElementById(id).style.display = 'block'
    },
    cardLeave(val) {
      let id = 'img' + val.id
      document.getElementById(id).style.display = 'none'
    },
    handleImgInfoClose() {
      this.imgInfoDrawer = false
    },
    handleBtn() {
      this.imgInfoDrawer = true
      this.drawerTabData = []
    },
    openMenu(e, index) {
      if (this.frames[`${this.selectLesion}-${this.activeName}`][index].addReport === 1) {
        this.isAdd = false
      } else {
        this.isAdd = true
      }
      this.addIndex = this.frames[`${this.selectLesion}-${this.activeName}`][index].id
      var x = e.pageX
      var y = e.pageY
      this.top = y
      this.left = x
      this.visible = true
    },
    closeMenu() {
      this.visible = false
    },
    handleClose(done) {
      done()
    },
    handleAdd(val) {
      let formData = new FormData()
      formData.append('printIndex', val.id)
      formData.append('addReport', '1')
      this.$api.post('/v1/webconsole/pacs/addReport', formData).then((res) => {
        this.addIndex = ''
        this.$message.success(res.data.msg)
        this.getImage('1')
      })
      this.imgInfoDrawer = false
    },
    handleDel() {
      let formData = new FormData()
      formData.append('printIndex', this.addIndex)
      formData.append('addReport', '0')
      this.$api.post('/v1/webconsole/pacs/addReport', formData).then((res) => {
        this.addIndex = ''
        this.$message.success(res.data.msg)
        this.getImage('1')
      })
    },
    nodeClick(lesion) {
      this.selectLesion = lesion.label
      this.tabData = lesion.child
      this.activeName = this.tabData[0] || ''
      this.getImage('1')
    },
    drawerNodeClick(lesion) {
      this.drawerSelectLesion = lesion.label
      this.drawerTabData = lesion.child
      this.drawerActiveName = this.drawerTabData[0] || ''
      this.getImage('0')
    },
    getMenu() {
      this.$api.get('/v1/webconsole/pacs/getDicomContext').then((res) => {
        if (res.status == 200) {
          this.menu = res.data.data
          let set = new Set()
          let map = {}
          for (let m of this.menu) {
            let arr = m.split('-')
            let op = arr.pop()
            let lesion = arr.join('-')
            set.add(lesion)
            let child = map[lesion] || []
            child.push(op)
            map[lesion] = child
          }
          this.data = []
          for (let m of set) {
            this.treeData.push({ label: m, child: map[m] })
          }
        }
      })
    },
    getImage(val) {
      if (this.currentPatient) {
        this.$api
          .get('/v1/webconsole/pacs/getImage', {
            addReport: val
          })
          .then((res) => {
            if (res.data.data) {
              let data = res.data.data
              let map = {}
              for (let frame of data) {
                let lesionAndOps = (frame.lesionName + '-' + frame.operationName).trim()
                if (!map[lesionAndOps]) map[lesionAndOps] = []
                let imgs = map[lesionAndOps]
                imgs.push({
                  url: frame.imageUrl,
                  addReport: frame.addReport,
                  id: frame.printIndex
                })
              }
              if (val === '0') {
                this.drawerFrames = map
                console.log(this.drawerFrames)
              } else {
                this.frames = map
              }
              this.$forceUpdate()
            }
          })
      }
    }
  }
}
</script>
